<template>
  <div class="card" :class="cardClass">
    <card-title :titleData="data" @handleItem="handleItem"></card-title>
    <div class="card-content-center">
      <div class="card-content">
        <div
          v-for="(item, index) in data.data"
          :key="item.name"
          @click="handleItem(index,item.id)"
          class="card-item-box"
        >
          <div class="card-item">
            <div class="card-item-icon">
              <img
              class="card-img"
                :src="require('@/assets/images/workbench' + item.icon)"
                alt=""
              />
            </div>
            <span class="card-item-title">{{ item.name }}</span>
          </div>
          <slot :index='index'></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cardTitle from "./card_title";
export default {
  components: {
    "card-title": cardTitle,
  },
  props: {
    data: {
      type: Object,
    },
    cardClass: {
      type: String,
    },
  },
  methods: {
    handleItem(index,id) {
      this.$emit("handleItem", index,id);
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  background: #fff;
  box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  .card-content {
    flex-wrap: wrap;
    padding: 16px 24px;
    display: flex;
    .card-item {
      display: flex;
      flex-direction: column;
      // align-items: center;
      // justify-content: center;
      margin-bottom: 20px;
      margin-right: 40px;
      .card-item-icon {
        width: 31px;
        height: 31px;
        overflow: hidden;
        margin: 0 auto 5px;

        img {
          width: 100%;
        }
      }
      .card-item-title {
        font-size: 13px;
        text-align: center;
        color: #374567;
      }
    }
    .card-item-box:nth-of-type(5n) .card-item{
      margin-right: 0;
    }
    .card-item-row {
      flex-direction: row;
      width: 28%;
      .card-item-icon {
        margin-bottom: 0;
        margin-right: 6px;
      }
    }
    .project-progress-icon {
      width: 30px;
    }
  }
}
</style>