<template>
  <el-menu
    :default-active="activeIndex + ''"
    class="el-menu-demo"
    mode="horizontal"
    @select="handleSelect"
  >
    <el-menu-item v-for="(val, index) in item" :key="val" :index="index + ''">
      <span v-if="!val.badge">{{ val.name }}</span>
      <el-badge :value="val.badge" class="item" v-else>{{ val.name }}</el-badge>
    </el-menu-item>
  </el-menu>
</template>

<script>
export default {
  props: {
    item: {
      type: Array,
    },
    activeIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  methods: {
    handleSelect(i) {
      this.$emit("select", i);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-menu-item {
  height: 50px;
  line-height: 50px;
  padding: 0;
  margin-right: 40px;
}
.el-menu-item.is-active {
  color: #00a0e9 !important;
}
::v-deep .el-badge__content.is-fixed {
  top: 30%;
}
</style>