<template>
  <div class="card-title">
    <div class="card-title-value">
      <img
        class="card-title-icon"
        :src="require('@/assets/images/workbench'+titleData.icon)"
        alt=""
      />
      <span>{{titleData.title}}</span>
    </div>
    <div @click="handleCilck" class="card-remark active-color">{{titleData.remark}}</div>
  </div>
</template>

<script>
export default {
  props:{
    titleData:{
      type:Object
    }
  },
  methods:{
    handleCilck(){
      this.$emit('handleItem');
    }
  }
};
</script>

<style lang="scss" scoped>
.card-title {
  font-size: 14px;
  color: #374567;
  padding: 16px 25px;
  border-bottom: 1px solid #e1e6f0;
  box-sizing: border-box;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  .card-remark {
    text-align: right;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
  }
  .card-title-icon {
    width: 18px;
    vertical-align: top;
    margin-right: 8px;
  }
}
</style>