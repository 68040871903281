import { render, staticRenderFns } from "./card_title.vue?vue&type=template&id=09b84012&scoped=true&"
import script from "./card_title.vue?vue&type=script&lang=js&"
export * from "./card_title.vue?vue&type=script&lang=js&"
import style0 from "./card_title.vue?vue&type=style&index=0&id=09b84012&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09b84012",
  null
  
)

export default component.exports