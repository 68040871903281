<template>
  <div id="workbench">
    <img src="@/assets/images/workbench/workbench_nothing_bar.png" alt="" />
    <el-row>
      <el-col class="width-12">
        <card class="card" :data="custom" @handleItem="handleCustom"></card>
        <img
          src="@/assets/images/workbench/statistic_model.png"
          alt=""
          class="card"
        />
      </el-col>
      <el-col class="width-75">
        <card
          class="card"
          :data="attract_project"
          @handleItem="handleAttractProject"
          :cardClass="'attract_project'"
        >
          <template slot-scope="index">
            <div class="card-project-progress-icon">
              <img
                v-if="index.index != 4"
                class="project-progress-icon"
                src="@/assets/images/workbench/attract_project/project-progress-icon.png"
                alt=""
              />
            </div>
          </template>
        </card>
        <img
          src="@/assets/images/workbench/examine_model.png"
          alt=""
          class="examine-card"
        />
        <todo></todo>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Card from "./components/card/card";
import Todo from "./todo/index";
import Empty from "@/components/empty";
export default {
  components: {
    Card,
    Empty,
    Todo,
  },
  data() {
    return {
      custom: {
        title: "招商事项台帐",
        icon: "/custom/title_icon.png",
        remark: "所有事项",
        path: "custom",
        data: [
          {
            name: "外出",
            icon: "/custom/custom_item_go.png",
          },
          {
            name: "接待",
            icon: "/custom/custom_item_come.png",
          },
        ],
      },
      attract_project: {
        title: "招商项目管理",
        icon: "/attract_project/title_icon.png",
        remark: "所有项目",
        path: "attractProject",
        data: [
          {
            name: "①项目登记",
            icon: "/attract_project/project-tab-1.png",
          },
          {
            name: "②拜访考察",
            icon: "/attract_project/project-tab-2.png",
          },
          {
            name: "③项目选址",
            icon: "/attract_project/project-tab-3.png",
          },

          {
            name: "④项目评审",
            icon: "/attract_project/project-tab-2.png",
          },
          {
            name: "⑤项目签约",
            icon: "/attract_project/project-tab-2.png",
          },
        ],
      },
    };
  },

  methods: {
    handleCustom(index) {
      this.routeEvent(this.custom, index);
    },
    handleAttractProject(index, id) {
      if (id) {
        this.routeEvent(this.attract_project, id);
        return;
      }
      this.routeEvent(this.attract_project, index);
    },
    routeEvent(item, index) {
      if (!isNaN(index)) {
        this.$router.push({
          path: item.path,
          query: { tab: index + 1 },
        });
        return;
      }
      this.$router.push({ path: item.path });
    },
  },
};
</script>

<style lang="scss" scoped>
#workbench {
  display: flex;
  background: #f3f7ff;
  padding: 25px 18px;
  box-sizing: border-box;
  > img {
    width: 18px;
    margin-right: 24px;
  }
  .examine-card {
    height: 180px;
  }
  .el-row {
    flex: 1;
    .width-12 {
      width: 26%;
      .card {
        min-height: 400px;
      }
    }
    .el-col {
      margin-right: 2%;
      > img {
        width: 100%;
        margin-bottom: 24px;
      }
    }
    .width-75 {
      width: 70%;
      margin-right: 0;
      .card {
        min-height: 196px;
      }
    }
    .width-12,
    .width-75 {
      .card {
        margin-bottom: 24px;
      }
    }
  }
}
::v-deep #empty .empty-icon {
  width: 40%;
}

::v-deep .attract_project .card-content {
  display: block;
  .card-item-box {
    float: left;
    height: 80px;
    display: flex;
    .card-item {
      margin-right: 0;
      margin: 0 auto;
      white-space: nowrap;
    }

    .card-project-progress-icon {
      height: 31px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 10px;
      width: 30px;
    }
  }
}
::v-deep .attract_project .card-content:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
</style>