<template>
  <div class="bg-white todo-box">
    <card-title :titleData="await_matter.title"></card-title>
    <div class="todo">
      <el-row type="flex" justify="space-between">
        <el-col :span="10">
          <tab-menu :item="item" @select="selectTabMenu"></tab-menu>
        </el-col>
        <el-col :span="10" class="right">
          <el-select size="mini" value="最近事项" placeholder="请选择" disabled>
            <el-option value="最近事项" label="最近事项"> </el-option>
          </el-select>
        </el-col>
      </el-row>
      <div>
        <todo-matter-table
          class="el-table"
          @showMatter="showMatter"
          :tableTh="await_matter.matterTableTh"
          :tableData="tableData"
          :height="'280'"
          :from="'pannel'"
          v-if="index == 0"
          
        >
        </todo-matter-table>
        <todo-project-table
          :tableTh="await_matter.projecTableTh"
          :tableData="tableData"
          :height="'280'"
          :from="'pannel'"
          ref="projectTable"
          v-else
        >
          <el-table-column width="100" label="审核状态">
            <template slot-scope="scope">
              <div
                :class="
                  scope.row.approveStatus == 0
                    ? 'active-color'
                    : 'approveStatus'
                "
                @click="cellClick(scope.row)"
              >
                {{ scope.row.approveStatus | approveFilter }}
              </div>
            </template>
          </el-table-column>
          <!-- <div slot="project-btns" slot-scope="{data}" class="dialog-footer btns">
              <div v-if="data.projectInfo.approveStatus==0" class="btn" @click="passEvent" >通过</div>
          </div> -->
        </todo-project-table>
      </div>
    </div>
    <show-project>
      <template slot="footer">
        <div class="dialog-footer">
          <div class="btns">
            <sapn class="btn" @click="passEvent">通过</sapn>
          </div>
        </div>
      </template>
    </show-project>
  </div>
</template>

<script>
import { getMatterList } from "@/api/crm/resource_library";
import { projectGetList, approve } from "@/api/crm/workbench";
import cardTitle from "../components/card/card_title";
import TabMenu from "@/components/tab_menus";
import TodoMatterTable from "../attract_matter/components/table";
import TodoProjectTable from "@/components/tables";
import { userVerify } from "@/api/user";
import showProject from "../attract_project/project_info";
export default {
  components: {
    cardTitle,
    TabMenu,
    TodoMatterTable,
    TodoProjectTable,
    showProject,
  },
  data() {
    return {
      index: 0,
      tableData: [],
      item: [
        {
          name: "我查阅的",
        },
      ],
      await_matter: {
        title: {
          title: "待办事项",
          icon: "/await_matter/title_icon.png",
        },
        matterTableTh: [
          {
            title: "事项名称",
            type: "itemName",
            className: "line-max-2 clicked",
            width: 280,
          },
          {
            title: "我方负责人",
            type: "cstName",
            className: "",
          },
          {
            title: "外出/接待事由",
            type: "reason",
            className: "",
            headerAlgin: "center",
          },
          {
            title: "执行时间",
            type: "time",
            className: "",
            headerAlgin: "center",
          },
        ],
        projecTableTh: [
          {
            title: "项目名称",
            type: "projName",
            className: "line-max-2",
          },
          {
            title: "归属企业",
            type: "entName",
            className: "line-max-2",
            width: 240,
          },

          {
            title: "我方负责人",
            type: "fzr",
            className: "",
          },
          {
            title: "客户负责人",
            type: "cstName",
            className: "",
          },
        ],
      },
    };
  },
  filters: {
    approveFilter(status) {
      switch (status) {
        case 1:
          return "已审核";
        case 0:
          return "未审核";
      }
    },
  },
  mounted() {
    this.getData();
    if (localStorage.getItem("auditor") == null) {
      this.userVerify();
    } else {
      if (localStorage.getItem("auditor") == 1) {
        this.item.push({
          name: "我审核的",
        });
        this.tableData = [];
      }
    }
  },
  methods: {
    userVerify() {
      userVerify().then((res) => {
        if (res.status == 200) {
          localStorage.setItem("auditor", res.data);
          if (res.data) {
            this.item.push({
              name: "我审核的",
            });
            this.tableData = [];
          }
        }
      });
    },
    passEvent() {
      const id = this.$store.getters.getById;
      approve(id).then((res) => {
        if (res.status == 200) {
          this.$store.commit("SET_DIALOGVISIBLE", false);
          this.getData();
        }
      });
    },
    selectTabMenu(index) {
      this.index = index;
      this.getData();
    },
    getData(searchVal) {
      if (this.index == 0) {
        getMatterList(`1/10`, {
          resId: 0,
          itemType: this.itemType,
          keyword: searchVal ? searchVal : "",
          timeSpan: 1,
        }).then((res) => {
          this.tableData = res.data;
        });
      } else {
        projectGetList(`1/100`, {
          approveStatus: 0,
        }).then((res) => {
          res.data.forEach((val) => {
            val.priorityClass = `priority_${val.priority}`;
            val.priority = val.priority;
            val.status = "项目登记";
          });
          this.tableData = res.data;
        });
      }
    },
    cellClick(e) {
      this.$store.commit("SET_ID", e.id);
      this.$store.commit("SET_DIALOGVISIBLE", true);
      this.$store.commit("SET_CHECK", 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.todo-box {
  height: 428px;
}
.approveStatus {
  color: #69748d;
}
.bg-white {
  background: #fff;
  .todo {
    padding: 10px 24px;
    .el-table {
      margin-top: 16px;
    }
    .el-menu.el-menu--horizontal {
      border-bottom: 0;
    }
    .right {
      margin-right: 0;
    }
  }
}
.btns{
  transform: translateX(-100px);
}
</style>